import React from "react";
import { HiPhone } from "react-icons/hi2";
import styles from "./CallButton.module.scss";

export default function CallButton() {
  return (
    <button className={styles["call-button"]}>
      <HiPhone color="#eee" size={18} />
      <span className={styles["call-button-text"]}>0800 042 0224</span>
    </button>
  );
}
