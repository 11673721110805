import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { HiOutlineIdentification } from "react-icons/hi2";
import { MdOutlineCheckCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import CallButton from "../../components/CallButton";
import Logo from "../../img/logo_empresa.png";
import { api6pCharges } from "../../services/api";
import styles from "./InsertDocument.module.scss";

const applicationId = process.env.REACT_APP_APPLICATION_ID;

export default function InsertDocument() {
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [recaptcha, setRecaptcha] = useState("");

  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      <header className={styles.header}>
        <div className={styles["header-container"]}>
          <img alt="" src={Logo} width={100} height={100} />
          <div>
            <CallButton />
          </div>
        </div>
      </header>

      <main className={styles.main}>
        <div className={styles["form-container"]}>
          <div className={styles["form-title-container"]}>
            <p className={styles["form-title"]}>Consulte seus</p>
            <p className={styles["form-title"]}>pagamentos em aberto.</p>
          </div>
          <form
            onSubmit={async (e) => {
              e.preventDefault();

              const cleanedDocument = cpfCnpj
                .replace(/\./g, "")
                .replace(/\//g, "")
                .replace(/-/g, "");

              if (
                // recaptcha &&
                // recaptcha.length &&
                cleanedDocument.length &&
                (cleanedDocument.length === 11 || cleanedDocument.length === 14)
              ) {
                try {
                  const res = await api6pCharges.post(
                    "/revolutionService/getDataEntity",
                    {
                      applicationId,
                      entityName: "_cadastro",
                      expressao: cleanedDocument,
                      fieldName: "_CryptCPFCNPJ",
                      relationId: "",
                    }
                  );

                  const { data } = res;
                  if (data.masterEntity) {
                    navigate("painel", { state: { data } });
                  }
                } catch (e) {
                  console.log(e);
                }

                const res1 = await api6pCharges.post(
                  "/revolutionService/getDataEntity",
                  {
                    applicationId,
                    entityName: "_titulo",
                    expressao: cleanedDocument,
                    fieldName: "_cryptclientecpfcnpj",
                    relationId: "",
                  }
                );
                console.log(res1);
                const res2 = await api6pCharges.post(
                  "/revolutionService/getDataEntity",
                  {
                    applicationId,
                    entityName: "_titulo",
                    expressao: res1.data.masterEntity[0].records[0].id,
                    fieldName: "id",
                    relationId: "",
                  }
                );
                console.log(res2);

                return; //navigate("painel");
              }
              //if (!recaptcha.length) alert("Preencha o reCAPTCHA!");

              if (
                cleanedDocument.length !== 11 &&
                cleanedDocument.length !== 14
              )
                alert("CPF ou CNPJ inválido!");
            }}
            className={styles.form}
          >
            <div className={styles["input-container"]}>
              <input
                className={styles.input}
                placeholder="CPF/CNPJ"
                value={cpfCnpj}
                onChange={(e) =>
                  setCpfCnpj(
                    e.target.value
                      .replace(/\D/g, "")
                      .replace(/(\d{3})(\d)/, "$1.$2")
                      .replace(/(\d{3}\.\d{3})(\d)/, "$1.$2")
                      .replace(/(\d{3}\.\d{3}\.\d{3})(\d)/, "$1-$2")
                      .replace(
                        /(\d{2})(\d)(\.)(\d{2})(\d)(\.)(\d{2})(\d)(-)(\d{2})(\d)/,
                        "$1$3$2$4$6$5$7/$8$10$11"
                      )
                      .replace(/(\d{2}\.\d{3}\.\d{3}\/\d{4})(\d)/, "$1-$2")
                      .replace(/(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})(.+)/, "$1")
                  )
                }
              />
              <HiOutlineIdentification color="#555" size={30} />
            </div>
            {/* <ReCAPTCHA
              sitekey="6Ld8Qh8qAAAAAKmA0fQ40l-rWJa2zgCQrb5m4AQU"
              onChange={(value) => setRecaptcha(value)}
            /> */}

            <button className={styles["button-container"]}>
              <span className={styles["button-label"]}>Entrar</span>
              <MdOutlineCheckCircle color="#eee" size={30} />
            </button>
          </form>
          <a
            target="_blank"
            href=" https://wa.me/5516994047397?text=Gostaria%20de%20ajuda%2E"
            className={styles["sac-text-button"]}
          >
            <p>Dúvidas ou dificuldades para acessar?</p>
            <p>Clique aqui.</p>
          </a>
        </div>
      </main>
    </div>
  );
}
