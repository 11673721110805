import axios from "axios";

export const api6pCharges = axios.create({
  baseURL: "https://banco6p.revolutioncrm.com.br:8095",
});

const username = process.env.REACT_APP_API_USER;
const password = process.env.REACT_APP_API_PASSWORD;
const credentials = username + ":" + password;

const auth = "Basic " + btoa(credentials);

api6pCharges.interceptors.request.use((config) => {
  config.headers.Authorization = auth;

  return config;
});
