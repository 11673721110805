import * as React from "react";
import Logo from "../../img/logo_empresa.png";
import styles from "./BillsPanel.module.scss";
import { useEffect, useState } from "react";
import CallButton from "../../components/CallButton";
import { ptBR, ptPT } from "@mui/material/locale";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import { api6pCharges } from "../../services/api";

const applicationId = process.env.REACT_APP_APPLICATION_ID;

function getTodayDate() {
  const date = new Date();
  const day =
    `${date.getDate()}`.length === 2 ? date.getDate() : `0${date.getDate()}`;
  const month =
    `${date.getMonth() + 1}`.length === 2
      ? date.getMonth() + 1
      : `0${date.getMonth() + 1}`;
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

function formatNumberToCurrency(value) {
  return Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}

const columns = [
  { field: "id" },
  // {
  //   field: "installments",
  //   headerName: "Nº da parcela",
  //   width: window.screen.width / 4.25,
  // },
  {
    field: "type",
    headerName: "Tipo de parcela", //normal ou acordo
    width: window.screen.width / 3.4,
  },
  {
    field: "dueDate",
    headerName: "Vencimento",
    width: window.screen.width / 3.4,
    sortable: true,
    valueGetter: (date) => {
      const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      const month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`;

      return `${day}/${month}/${date.getFullYear()}`;
    },
  },
  {
    field: "value",
    headerName: "Valor",
    sortable: true,
    width: window.screen.width / 3.4,
    valueGetter: formatNumberToCurrency,
  },
];

const { innerWidth } = window;

export default function BillsPanel() {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [username, setUsername] = useState("");
  const [rows, setRows] = useState([]);

  const [total, setTotal] = useState(0);

  const [unitiesList, setUnitiesList] = useState([]);
  const [selectedUnity, setSelectedUnity] = useState(null);

  const [titleId, setTitleId] = useState("");
  const [installments, setInstallments] = useState([]);

  const [installmentIds, setInstallmentIds] = useState([]);

  const existingTheme = useTheme();

  const theme = React.useMemo(
    () => createTheme({}, ptBR, existingTheme, {}),
    [existingTheme]
  );

  async function getOverdueBills(id, creditorId) {
    const res = await api6pCharges.post(
      "/cobrancaService/getParcelasVencidasVincendasPortal",
      {
        applicationId,
        dataCalculo: getTodayDate(),
        relationId: "",
        tipoNegociacao: 1,
        tituloId: id,
      }
    );

    console.log(
      "vincendas",
      id,
      res.data.filter((item) => {
        console.log(item._tituloBoletoAVencerRegistradoId);
        return item._tituloBoletoAVencerRegistradoId !== null;
      })
    );

    if (res.data.length && res.data.length > 0) {
      setRows(
        res.data.map((item) => {
          return {
            id: item.ID + "-" + (item._tituloBoletoAVencerRegistradoId ?? ""),
            // installments: item._parcela,
            type: item._isacordo ? "Acordo" : "Normal",
            dueDate: new Date(item._vencimento),
            value: item._totalAPagarAtualizacao,
          };
        })
      );
      setLoading(false);
    } else alert("Nenhuma conta em aberto encontrada!");
  }

  async function getOpenBills(id) {
    setLoading(true);

    const res = await api6pCharges.post(
      "/cobrancaService/getTitulosEmAbertoCliente",
      {
        applicationId,
        clienteID: id,
        relationId: "",
      }
    );

    const [item] = res.data;

    setTitleId(item.tituloid);

    await getOverdueBills(item.tituloid, item.credorid);
  }

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");

    const date = getTodayDate().split("-").reverse().join("-");
    const fileName = `boleto-6pbank.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;

    downloadLink.click();
  }

  const compareDates = (d1) => {
    const todayArr = getTodayDate()
      .split("-")
      .map((item, i) => {
        if (i !== 1) return Number(item);

        return Number(item) - 1;
      });

    const date1 = new Date(d1).getTime();
    const date2 = new Date(todayArr[0], todayArr[1], todayArr[2]).getTime();

    const differenceInTime = date2 - date1;
    const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

    return differenceInDays > 10;
  };

  async function generateBill() {
    // const res = await api6pCharges.post("/boletoService/gerarBoleto", {
    //   applicationId,
    //   contaCorrenteId: "",
    //   enderecoId: "",
    //   parcelas: installments.map((item) => item.id),
    //   relationId: "PORTAL",
    //   tituloId: titleId,
    //   valorBoleto: total,
    //   vencimento: getTodayDate(),
    // });

    const someInstallmentHasReachedOverdue = installments.some((item) =>
      compareDates(item.overdue)
    );

    try {
      const billId = installmentIds[0].split("-")[1];

      console.log(billId);

      if (!billId)
        alert(
          "O boleto para a parcela selecionada não está registrado ou não existe. Por favor entre em contato com a central através do número 0800 042 0224."
        );
      else if (someInstallmentHasReachedOverdue)
        alert(
          "A parcela selecionada está vencida há mais de 10 dias. Por favor entre em contato com a central através do número 0800 042 0224."
        );
      else {
        const res = await api6pCharges.post("/boletoService/getBoletoPDF", {
          applicationId: "1K3K4H5N6BV69084J3UEYS63485091KL",
          relationId: "api6p",
          tituloBoletoId: billId,
        });

        downloadPDF(res.data._arquivo);

        console.log(res);
      }
    } catch (e) {
      alert("Boleto não localizado!");
    }

    // const someInstallmentHasReachedOverdue = installments.some((item) =>
    //   compareDates(item.overdue)
    // );
    // if (someInstallmentHasReachedOverdue)
    //   alert(
    //     "Atenção! Uma das cobranças está vencida há mais de 10 dias. Você deve aguardar 60 minutos para pagar o boleto que foi gerado!"
    //   );

    // downloadPDF(res.data.arquivopdf);
  }

  useEffect(() => {
    if (location?.state?.data) {
      const { masterEntity } = location.state.data;

      const { records } = masterEntity[0];

      if (records.length > 1)
        setUnitiesList(
          records.map((item) => ({
            value: item.id,
            text: item._cryptnome,
            name: item._cryptnome,
          }))
        );
      else {
        setSelectedUnity(records[0].id);
        setUsername(records[0]._cryptnome);
      }
    } else navigate("/");
  }, []);

  useEffect(() => {
    if (selectedUnity) getOpenBills(selectedUnity);
  }, [selectedUnity]);

  console.log(installments);

  return (
    <div className={styles.page}>
      <header className={styles.header}>
        <div className={styles["header-container"]}>
          <img alt="" src={Logo} width={100} height={100} />
          {innerWidth > 500 && (
            <p className={styles["header-text-title"]}>Contas em aberto</p>
          )}
          <div>
            {username && (
              <p className={styles["header-text-welcome"]}>
                Bem-vindo(a), {username}!
              </p>
            )}
          </div>
        </div>
      </header>
      <main className={styles.main}>
        <div
          className={[
            styles["table-component-container"],
            styles["flex-bottom"],
          ].join(" ")}
        >
          {total > 0 && (
            <p className={styles.total}>
              Valor total: {formatNumberToCurrency(total)}
            </p>
          )}
        </div>
        <div style={{ height: 400, width: "100%" }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              initialState={{
                columns: { columnVisibilityModel: { id: false } },
              }}
              loading={loading}
              slotProps={{
                loadingOverlay: {
                  variant: "linear-progress",
                  noRowsVariant: "skeleton",
                },
              }}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              disableColumnResize
              localeText={ptBR}
              rowSelectionModel={installmentIds}
              onRowSelectionModelChange={(selectedRowIds, details) => {
                const selectedId = selectedRowIds[selectedRowIds.length - 1];

                if (selectedId) {
                  const selected = rows.find((row) => row.id === selectedId);

                  setInstallmentIds([selectedId]);

                  // const selectedRows = rows.filter((row) =>
                  //   selectedRowIds.includes(row.id)
                  // );

                  // setInstallments(
                  //   selectedRows.map((row) => ({
                  //     id: row.id,
                  //     overdue: row.dueDate,
                  //   }))
                  // );

                  setInstallments([
                    { id: selected.id, overdue: selected.dueDate },
                  ]);

                  // const values = selectedRows.map((row) => row.value);
                  // const total = values.reduce((total, value) => total + value, 0);
                  // setTotal(total);

                  setTotal(selected.value);
                } else {
                  setInstallmentIds([]);
                  setInstallments([]);
                  setTotal(0);
                }
              }}
            />
          </ThemeProvider>
        </div>
        <div
          className={[
            styles["table-component-container"],
            styles["flex-center"],
          ].join(" ")}
        >
          {total > 0 && (
            <button
              onClick={generateBill}
              className={styles["generate-bill-button"]}
            >
              <span className={styles["generate-bill-button-text"]}>
                Gerar boleto
              </span>
            </button>
          )}
        </div>
      </main>
      <footer className={styles.footer}>
        <div className={styles["footer-container"]}>
          <p className={styles["footer-phrase"]}>O banco do seu condomínio!</p>
          <CallButton />
        </div>
      </footer>

      {!selectedUnity && unitiesList.length && (
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <p className={styles["modal-title"]}>Selecione a unidade</p>
            <div style={{ marginTop: 10 }}>
              {unitiesList.map((item) => (
                <div
                  className={styles["modal-item"]}
                  onClick={() => {
                    setSelectedUnity(item.value);
                    setUsername(item.name);
                  }}
                >
                  {item.text}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
