import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import InsertDocument from "./pages/InsertDocument";
import BillsPanel from "./pages/BillsPanel";

const router = createBrowserRouter([
  {
    path: "/",
    element: <InsertDocument />,
  },
  {
    path: "/painel",
    element: <BillsPanel />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
